import { Component } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import Pannel from "./Pannel";
import Header from "./Header";
import Api from "../../Api";
import Dropdown from "react-dropdown";
import MultipleDropdown from "./MultipleDropdown";
import { loc, includesMultiType, isNullable } from "../../helpers";
import { DownloadCSV, NbRows } from "./Table";
import { InputForm } from "./InputForm";
import ReactDropdown from "react-dropdown";
import { ToastContext } from "../../contexts/ToastContext";
import { FilterMoba } from "./FilterMoba";
import MobaTable from "./MobaTable";
import PlusBubble from "../components/PlusBubble";
import SideModal from "../components/SideModal";
import Tooltip from "../components/Tooltip";
import LoadingSpinner from "../components/LoadingSpinner";
import Helper from "../components/Helper";

import mixpanel from "mixpanel-browser";

Chart.register(CategoryScale);

class Sites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_details: null,
      moba: props.moba,
      showConfirmation: false,
      site_to_delete: null,
      site_to_delete_id: null,
      showSiteActionModal: false,
      site_choosen: null,
      dongle_choosen: null,
      site_name: null,
      society_name: null,
      street: null,
      city: null,
      postal_code: null,
      country_chosen: null,
      vat_number: null,
      different_delivery_address: false,
      delivery_society_name: null,
      delivery_street: null,
      delivery_city: null,
      delivery_postal_code: null,
      selector_admin: { selectedList: [] },
      selected_admins: [],
      selector_managers: { selectedList: [] },
      selected_managers: [],
      selector_dongles: { selectedList: [] },
      selected_dongles: [],
      site_action: "CREATE",
      site_to_update_id: 0,
      sites: null,
      showDongle: false
    };


    // this.filterableDongles = props.moba.getFilterableDongles(true);

    this.helpers = {
      siteDongles: loc(
        "L'association d'un boîtier est une fonctionnalité uniquement organisationnelle. Elle n'empêche pas le boîtier d'être utilisé sur un autre site.",
        props.lang
      )
    };

    this.getSiteSubmitButtonLabel = () => {
      return this.state.site_action === "CREATE" ? loc("Créer", this.props.lang) : loc("Modifier", this.props.lang);
    }
    this.getSiteModalTitle = () => {
      return this.state.site_action === "CREATE" ? loc("Créer un site", this.props.lang) : loc("Modifier un site", this.props.lang);
    }
    this.getSiteSubmitEndpoint = () => {
      return this.state.site_action === "CREATE" ? "mobaCreateSite" : "mobaEditSite";
    }

    this.initSites = () => {
      const filterableSites = props.moba.getFilterableSites(false);
      const filterableCountries = props.moba.getFilterableCountries(true);

      filterableSites.map((site) => {
        site.country = filterableCountries[site.country] || {id: null, name: "-"};
      });

      this.setState({
        sites: filterableSites,
      });
    };

    this.deleteSiteOpen = (id, name) => {
      this.setState({
        site_to_delete_id: id,
        showConfirmation: true,
        site_to_delete: name,
      });
    };

    this.closeDeleteSite = () => {
      this.setState({ showConfirmation: false });
    };

    this.showCreateSite = () => {
      this.setState({ 
        showSiteActionModal: true,
        site_action: "CREATE",
       });
    };

    this.showEditSite = ({
      name,
      name_society,
      street,
      city,
      postal_code,
      country,
      admins,
      managers,
      site_id,
      delivery_society_name,
      delivery_street,
      delivery_city,
      delivery_postal_code,
      vat_number,
      delivery_country,
      dongles
    }) => {
      this.state.selector_admin.selectedList.splice(0, this.state.selector_admin.selectedList.length);
      admins.forEach((admin) => {
        this.state.selector_admin.selectedList.push(admin);
        this.state.selected_admins.push(admin);
      });
      
      this.state.selected_managers.splice(0, this.state.selected_managers.length);
      managers.forEach((manager) => {
        this.state.selector_managers.selectedList.push(manager);
        this.state.selected_managers.push(manager);
      });

      this.state.selected_dongles.splice(0, this.state.selected_dongles.length);
      dongles.forEach((dongle) => {
        this.state.selector_dongles.selectedList.push(dongle);
        this.state.selected_dongles.push(dongle);
      });

      this.setState({
        showSiteActionModal: true,
        site_action: "EDIT",
        site_name: name,
        society_name: name_society,
        selected_admins: admins,
        street: street,
        city: city,
        postal_code: postal_code,
        site_to_update_id: site_id,
        vat_number: vat_number,
        country_chosen: country,
        different_delivery_address: delivery_society_name !== null,
        delivery_society_name: delivery_society_name,
        delivery_street: delivery_street,
        delivery_city: delivery_city,
        delivery_postal_code: delivery_postal_code,
      });
    };
    this.deleteSite = (id) => {
      this.state.moba.deleteSite(id, (response) => {
        this.handleClose();
        if (response.err) {
          this.context.showToast(
            loc("Erreur", this.props.lang),
            response.msg,
            "error"
          );
        }
        this.context.showToast(
          loc("Site supprimé", this.props.lang),
          loc("Le site a été supprimé avec succès", this.props.lang),
          "success"
        );
        this.updateInfos(true);
        mixpanel.track("User Deleted Site", {
          "Target Site ID": id
        });
      });
    };

    this.updateInfos = (force = false) => {
      this.state.moba.updateInfos(() => {
        this.setState({
          entity_details: this.state.moba.entity_details,
        });
        this.initSites();
      }, force);
    };

    this._onSelectSite = (site) => {
      this.setState({ site_choosen: site.value });
    };

    this._onSelectCountry = (country) => {
      this.setState({ country_chosen: country.value });
    };

    this.handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
    this.handleClose = () => {
      this.setState({
        showDongle: false,
        showSiteActionModal: false,
        site_name: null,
        society_name: null,
        street: null,
        city: null,
        postal_code: null,
        site_to_update_id: 0,
        site_choosen: null,
        country_chosen: null,
        dongle_choosen: null,
        selected_admins: [],
        selected_managers: [],
        selected_dongles: [],
        selector_admin: { selectedList: [] },
        selector_managers: { selectedList: [] },
        selector_dongles: { selectedList: [] },
      });
    };

    this.handleShowDongle = () => {
      if (!this.props.moba.isSuperAdmin()) {
        return;
      }
      this.setState({
        showDongle: true,
      });
    };

    this._onSelectDongle = (dongle) => {
      this.setState({ dongle_choosen: dongle });
    };

    this._onSelectSite = (site) => {
      this.setState({ site_choosen: site });
    };

    this.handleSiteCreateOrEdit = () => {
      const apiEndpoint = this.getSiteSubmitEndpoint();

      const toastTitle = this.state.site_action === "CREATE" ? loc("Site créé", this.props.lang) : loc("Site modifié", this.props.lang);
      const toastMessage = this.state.site_action === "CREATE" ? loc("Le site a été créé avec succès", this.props.lang) : loc("Le site a été modifié avec succès", this.props.lang);
      const trackEvent = this.state.site_action === "CREATE" ? "User Created Site" : "User Edited Site";
      new Api().fetch(
        apiEndpoint,
        {
          id: this.state.site_to_update_id,
          name: this.state.site_name,
          site_admin: this.state.selector_admin.selectedList.map((a) => a.id),
          site_manager: this.state.selector_managers.selectedList.map(
            (a) => a.id
          ),
          society_name: this.state.society_name,
          street: this.state.street,
          postal_code: this.state.postal_code,
          city: this.state.city,
          country: this.state.country_chosen,
          vat_number: this.state.vat_number,
          delivery_society_name: this.state.delivery_society_name,
          delivery_street: this.state.delivery_street,
          delivery_postal_code: this.state.delivery_postal_code,
          delivery_city: this.state.delivery_city,
          dongles: this.state.selector_dongles.selectedList.map((a) => a.id),
        },
        (result) => {
          this.handleClose();
          if (result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result.msg,
              "error"
            );
          } else {
            this.updateInfos(true);
            this.context.showToast(
              toastTitle,
              toastMessage,
              "success"
            );
            mixpanel.track(trackEvent, {
              "Target Site ID": result.site.id,
              "Target Site Name": result.site.name
            });
          }
        }
      );
    };

    this.handleAssignDongles = () => {
      const dongles = this.state.selector_dongles.selectedList.map((a) => a.id);
      const site = this.state.site_choosen.value;

      new Api().fetch(
        "mobaAssignManyDongles",
        { site, dongles },
        (result) => {
          this.handleClose();
          if (!result || result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result?.msg
                ? result.msg
                : loc("Une erreur est survenue", this.props.lang),
              "error"
            );
          } else {
            this.context.showToast(
              loc("Dongle(s) associés", this.props.lang),
              loc(
                "Le(s) dongle(s) ont été associés avec succès au site",
                this.props.lang
              ),
              "success"
            );
            this.updateInfos(true);

            mixpanel.track("User Paired Devices", {
              "Target Site ID": site,
              "Target Devices ID": dongles
            });
          }
        }
      );
    };

    this.columns = [
      {
        Header: "Site",
        accessor: "name",
				Filter: FilterMoba,
				filter: (rows, col_id, filterValue) => {
          return rows.filter((row) => {
            let valid = true;
  
            for (let filterKey in filterValue) {
              const allowEmpty = filterValue[filterKey] === null || includesMultiType(null, filterValue[filterKey], "id");

              if (filterValue[filterKey] !== undefined && filterValue[filterKey] !== null) {
                if (isNullable(row.original[filterKey])) {
                  valid = allowEmpty
                } else {
                  valid = includesMultiType(
                    row.original[filterKey],
                    filterValue[filterKey],
                    "id"
                  );
                }
              }
              if (!valid) {
                break;
              }
            }
            return valid;
          });
        },
        moba: this.state.moba,
        lang: this.props.lang,
        filters: ["COUNTRY"],
        Cell: (row) => {
          return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">
                { row.row.original.name }
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: loc("Pays", this.props.lang),
        accessor: "country",
        sortType: "object",
        type: loc("pays", this.props.lang),
        Cell: (row) => {
          return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">
                {row.row.original.country ? `${row.row.original.country.emoji || ""} ${row.row.original.country.name}` : '-'}
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: loc("Adresse de facturation", this.props.lang),
        accessor: "billing_addresses",
        type: loc("site", this.props.lang),
        typePlural: loc("sites", this.props.lang),
        Filter: NbRows,
        Cell: (row) => {
          const toDisplay = row.row.original.billing_addresses
            ? row.row.original.billing_addresses.street +
              ", " +
              row.row.original.billing_addresses.postal_code +
              " " +
              row.row.original.billing_addresses.city +
              row.row.original.billing_addresses.country
            : "";
          return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">
                { row.row.original.billing_addresses ? toDisplay : "-" }
              </div>
            </Tooltip>
          );
        },
      },
      // {
      //   Header: loc("Site Admin(s)", this.props.lang),
      //   accessor: "site_admins",
      //   sortType: "object",
      //   Cell: (row) => {
      //     return (
      //       row.row.original.site_admins.length > 0 && (
      //         <div className="flex overflow-hidden p-1 align-center">
      //           <p className="font-normal text-xs m-auto whitespace-nowrap overflow-ellipsis overflow-hidden">
      //             {loc(row.row.original.site_admins[0].user, this.props.lang)}
      //           </p>
      //           <PlusBubble
      //             data={row.row.original.site_admins}
      //             field="user"
      //             lang={this.props.lang}
      //           />
      //         </div>
      //       )
      //     );
      //   },
      // },
      { // À rétablir lorsque la notion de Site Manager aura été définie
        Header: loc("Site Manager(s)", this.props.lang),
        accessor: "site_managers",
        sortType: "object",
        lang: this.props.lang,
        Cell: (row) => {
          return (
            row.row.original.site_managers.length > 0 && (
              <div className="flex overflow-hidden p-1 align-center">
                <p className="font-normal text-xs m-auto whitespace-nowrap overflow-ellipsis overflow-hidden">
                  {loc(row.row.original.site_managers[0].user, this.props.lang)}
                </p>
                <PlusBubble
                  data={row.row.original.site_managers}
                  field="user"
                  lang={this.props.lang}
                />
              </div>
            )
          );
        },
      },
      { // À rétablir lorsque la notion de Site Manager aura été définie
        Header: loc("Boîtiers", this.props.lang),
        accessor: "dongles",
        sortType: "object",
        lang: this.props.lang,
        Cell: (row) => {
          return (row.row.original.dongles || []).length;
        },
      },
      {
        Header: loc(" ", this.props.lang),
        Filter: DownloadCSV,
        lang: this.props.lang,
        type: loc("sites", this.props.lang),
        id: "download-csv",
        Cell: (row) => {
          return (
            <div className="flex flex-1 justify-end gap-5">
              <button
                className="group mr-3"
                onClick={(e) => {
                  this.showEditSite({
                    name: row.row.original.name,

                    name_society: row.row.original.billing_addresses !== (undefined || null)
                      ? row.row.original.billing_addresses.society_name
                      : "",
                    street: row.row.original.billing_addresses !== (undefined || null)
                      ? row.row.original.billing_addresses.street
                      : "",
                    city: row.row.original.billing_addresses !== (undefined || null)
                      ? row.row.original.billing_addresses.city
                      : "",
                    postal_code: row.row.original.billing_addresses !== (undefined || null)
                      ? row.row.original.billing_addresses.postal_code
                      : "",
                    country: row.row.original.billing_addresses !== (undefined || null)
                      ? row.row.original.billing_addresses.country
                      : "",
                    admins: row.row.original.admin_team.members
                      .filter((member) => member.role === "admin")
                      .map((member) => {
                        return {
                          name: member.user,
                          id: member.userId,
                        };
                      }),
                    managers: row.row.original.admin_team.members
                      .filter((member) => member.role === "manager")
                      .map((member) => {
                        return {
                          name: member.user,
                          id: member.userId,
                        };
                      }),
                    site_id: row.row.original.id,
                    dongles: row.row.original.dongles,
                  });
                }}
                href={row.row.original.url}
                target="_blank"
              >
                <Tooltip message={loc("Modifier le site", props.lang)} position='left'>
                  <img
                    className=" filter grayscale hover:grayscale-0"
                    src="assets/edit.svg"
                    width="20"
                    alt="edit"
                  />
                </Tooltip>
              </button>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Sites" });
    this.updateInfos();
    //get url after ?
    let url = window.location.href.split("?")[1];
    //if args create = true then show create site
    if (url !== undefined && url.split("=")[1] === "create") {
      this.showCreateSite();
    } else if (url !== undefined && url.split("=")[1] === "assignDongle") {
      this.handleShowDongle();
    }

    // Rewrite URL to remove the query string
    window.history.pushState(
      "",
      document.title,
      window.location.pathname
    );

    this.state.moba.fetchAvailableCountries();
  }

  render() {
    if (this.state.moba.isLoading() || this.state.sites === null || !this.state.entity_details)
      return (
        <div className="flex flex-row min-h-screen m-0">
          <Pannel type="sites" lang={this.props.lang} />
					<LoadingSpinner/>
        </div>
      );
    else
      return (
        <div className="flex h-full min-h-screen overflow-y-hidden">
          <Pannel type="sites" lang={this.props.lang} />
          <div className="flex flex-col flex-grow">
            <Header
              connected={true}
              moba={this.props.moba}
              lang={this.props.lang}
            />
            <div className="mt-5 flex flex-1">
              <div className="flex-grow mx-8">
                <div className="flex h-full flex-col m-auto w-full">
                  <h1 className="font-Inter font-bold text-4xl text-grey-700">
                    Sites
                  </h1>
                  {this.props.page === "sites" && (
                    <>
                      <div className="flex flex-1 my-4" id="TABLE_CONTAINER">
                        <MobaTable
                          data={this.state.sites}
                          columns={this.columns}
                          show={this.showCreateSite}
                          add={this.props.moba.isSuperAdmin() ? loc("Ajouter un site", this.props.lang) : null}
                          lang={this.props.lang}
                          moba={this.state.moba}
                        />
                        <SideModal
                          show={this.state.showSiteActionModal}
                          title={this.getSiteModalTitle()}
                          onCancel={this.handleClose}
                          onConfirm={this.handleSiteCreateOrEdit}
                          lang={this.props.lang}
                          confirmLabel={this.getSiteSubmitButtonLabel()}
                          confirmDisabled={
                            !this.state.site_name ||
                            !this.state.society_name ||
                            !this.state.country_chosen ||
                            !this.state.street ||
                            !this.state.city ||
                            !this.state.postal_code
                          }
                        >
                          <InputForm
                            lang={this.props.lang}
                            defaultValue={this.state.site_name}
                            handleChange={this.handleChange}
                            name="site_name"
                            title={loc("Nom du site", this.props.lang)}
                            required={true}
                            placeholder={loc("Site 1", this.props.lang)}
                          />
                          <MultipleDropdown
                            title={
                              <>
                                {loc(
                                  "Manager(s) du site",
                                  this.props.lang
                                )}
                              </>
                            }
                            options={this.state.entity_details.users.map(
                              (user) => {
                                return {
                                  name: user.firstname + " " + user.lastname,
                                  id: user.id,
                                };
                              }
                            )}
                            selector={this.state.selector_managers}
                            selectedValue={this.state.selected_managers}
                          ></MultipleDropdown>

                          <p className="mt-4 text-shadow font-aeonik text-3xl font-normal">
                            Adresse
                          </p>
                          <InputForm
                            lang={this.props.lang}
                            defaultValue={this.state.society_name}
                            handleChange={this.handleChange}
                            name="society_name"
                            title={loc("Nom de la société", this.props.lang)}
                            required={true}
                            placeholder={loc("Société 1...", this.props.lang)}
                          />
                          <div className="mt-4">
                            <label className="text-sm ml-0 mr-auto mb-2 font-aeonik text-gray-600 font-medium">
                              {loc("Pays", this.props.lang)}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <ReactDropdown
                              options={this.state.moba.available_countries
                                .sort((a, b) => (a.name < b.name ? -1 : 1))
                                .map((country) => {
                                  return {
                                    label: `${country.emoji} ${country.name}`,
                                    value: country.id,
                                  };
                                })}
                              onChange={this._onSelectCountry}
                              value={this.state.country_chosen}
                              placeholder={loc(
                                "Rechercher...",
                                this.props.lang
                              )}
                            />
                          </div>
                          <InputForm
                            lang={this.props.lang}
                            defaultValue={this.state.street}
                            handleChange={this.handleChange}
                            name="street"
                            title={loc("Numéro et nom de rue", this.props.lang)}
                            required={true}
                            placeholder={`${loc(
                              "170 Rue Blanche",
                              this.props.lang
                            )}...`}
                          />
                          <div className="flex max-w-full justify-between">
                            <div className="flex flex-col">
                              <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                                {loc("Ville", this.props.lang)}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <input
                                type="text"
                                className="px-4 border-1 border-grey-300 h-11 rounded-lg shadow-xs placeholder:font-aeonik w-36"
                                defaultValue={this.state.city}
                                onChange={this.handleChange}
                                name="city"
                                required={true}
                                placeholder="Paris..."
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                                {loc("Code postal", this.props.lang)}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <input
                                type="text"
                                className="px-4 border-1 border-grey-300 h-11 rounded-lg shadow-xs placeholder:font-aeonik w-36"
                                defaultValue={this.state.postal_code}
                                onChange={this.handleChange}
                                name="postal_code"
                                required={true}
                                placeholder="75000"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col">
                              <MultipleDropdown
                                title={
                                  <div className="flex items-center">
                                    {loc("Boîtiers associé(s)", this.props.lang)}
                                    <Helper className="ml-2" text={this.helpers.siteDongles} position='top' />
                                  </div>
                                }
                                options={this.state.entity_details.assignableDongles.concat(this.state.selected_dongles)}
                                selector={this.state.selector_dongles}
                                selectedValue={this.state.selected_dongles}
                                displayValue="code"
                                disabled={!this.props.moba.isSuperAdmin()}
                              ></MultipleDropdown>
                            </div>
                        </SideModal>
                        <SideModal
                          show={this.state.showDongle}
                          title={loc("Associer un boitier", this.props.lang)}
                          onCancel={this.handleClose}
                          lang={this.props.lang}
                          confirmLabel={loc("Associer", this.props.lang)}
                          onConfirm={this.handleAssignDongles}
                          confirmDisabled={
                            !this.state.selector_dongles.selectedList.length ||
                            !this.state.site_choosen
                          }
                        >
                          <div className="w-full">
                            <MultipleDropdown
                              title={
                                <div className="flex items-center">
                                  {loc("Boîtiers", this.props.lang)}
                                  <span style={{ color: "red" }}> *</span>
                                  <Helper className="ml-2" text={this.helpers.siteDongles} position='top' />
                                </div>
                              }
                              onChange={this._onSelectDongle}
                              options={this.state.entity_details.assignableDongles}
                              selector={this.state.selector_dongles}
                              selectedValue={this.state.selected_dongles}
                              displayValue="code"
                            ></MultipleDropdown>
                          </div>
                          <div className="w-full">
                            <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                              {loc("Site", this.props.lang)}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Dropdown
                              options={this.state.entity_details.sites
                                .map((site) => {
                                  return {
                                    label: site.name,
                                    value: site.id,
                                  };
                                })}
                              onChange={this._onSelectSite}
                              value={this.state.site_choosen}
                              placeholder={loc("Choisissez une option", this.props.lang)}
                            />
                          </div>
                        </SideModal>
                      </div>
                    </>
                  )}
                  {this.props.page === "billing" && <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

Sites.contextType = ToastContext;

export default Sites;
